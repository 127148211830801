import React from 'react'

interface ButtonProps {
    children: string,
    classes?: string,
}

function Button({ children = '', classes = '' }: ButtonProps) {
    return (
        <button className={`bg-accent text-white hover:bg-accent/80 font-bold py-auto py-auto uppercase tracking-widest text-tiny py-3 clipped-box ${classes}`} type="submit">
            {children}
        </button>
    )
}

export default Button